import { Controller } from "@hotwired/stimulus"
import { venn, lossFunction, normalizeSolution, scaleSolution, computeTextCentres } from "../layout"

// Connects to data-controller="vega"
export default class extends Controller {
  static values = {
    spec: String,
    element: String,
    spark: Array,
    bullet: Array,
    spectrum: Array,
    venn: Array,
    options: Object
  }

  connect() {
    const div = document.createElement('div');

    if (this.specValue > "") {
      const el = document.getElementById(this.elementValue);
      el?.firstElementChild?.classList.add('blur-lg')
      vegaEmbed(div, this.specValue, { actions: false }).then(function (result) {
        el.replaceChildren(div);
        el.value = result.view // Access the Vega view instance (https://vega.github.io/vega/docs/api/view/)
      }).catch(console.error);
    }

    if (this.sparkValue.length > 0) {
      const el = this.element;
      vegaEmbed(div, { ...this.sparkline(this.sparkValue), ...this.optionsValue }, { actions: false }).then(function (result) {
        el.replaceChildren(div);
        el.value = result.view // Access the Vega view instance (https://vega.github.io/vega/docs/api/view/)
      }).catch(console.error);
    }

    if (this.bulletValue.length > 0) {
      const el = this.element;
      vegaEmbed(div, this.bullet(this.bulletValue), { actions: false }).then(function (result) {
        el.replaceChildren(div);
        el.value = result.view // Access the Vega view instance (https://vega.github.io/vega/docs/api/view/)
      }).catch(console.error);
    }

    if (this.spectrumValue.length > 0) {
      const el = this.element;
      vegaEmbed(div, this.spectrum(this.spectrumValue), { actions: false }).then(function (result) {
        el.replaceChildren(div);
        el.value = result.view // Access the Vega view instance (https://vega.github.io/vega/docs/api/view/)
      }).catch(console.error);
    }

    if (this.vennValue.length > 0) {
      const el = this.element;
      vegaEmbed(div, this.vennchart(this.vennValue), { actions: false }).then(function (result) {
        el.replaceChildren(div);
        el.value = result.view // Access the Vega view instance (https://vega.github.io/vega/docs/api/view/)
      }).catch(console.error);
    }
  }

  vennchart(data) {
    console.log(data);
    // handle 0-sized sets by removing from input
    var toremove = {};
    data.forEach(function (datum) {
      if ((datum.size == 0) && datum.sets.length == 1) {
        toremove[datum.sets[0]] = 1;
      }
    });
    data = data.filter(function (datum) {
      return !datum.sets.some(function (set) { return set in toremove; });
    });

    var circles = {};

    if (data.length > 0) {
      var solution = venn(data, { lossFunction: lossFunction });
      solution = normalizeSolution(solution, null, null);
      circles = scaleSolution(solution, 200, 200, 0);
    }

    // work with a list instead of a dictionary
    var result = [], setid;
    for (setid in circles) {
      if (circles.hasOwnProperty(setid)) {
        var previous = circles[setid];
        result.push({
          x: previous.x,
          y: previous.y,
          radius: previous.radius,
          size: 4 * previous.radius * previous.radius,
          setid: setid
        });
      }
    }

    const positions = Object.entries(computeTextCentres(circles, data)).map(([key, value]) => {
      return {...value, setid: key};
    })

    console.log(positions);

    console.log(circles);

    return {
      $schema: "https://vega.github.io/schema/vega/v5.json",
      width: 200,
      height: 200,
      padding: 0,
      data: [
        { name: "source", values: result },
        { 
          name: "textsource", 
          values: positions,
          transform: [
            {type: "filter", expr: "datum.y > 0"}
          ]
        },
      ],
      scales: [
        {
          name: "color",
          type: "ordinal",
          domain: { data: "source", field: "setid" },
          "range": ["red", "green", "blue"]
        }
      ],
      marks: [
        {
          name: "circles",
          type: "symbol",
          from: { data: "source" },
          encode: {
            hover: {
              opacity: { value: 0.3 },
              // tooltip: {
              //   signal: "{'Name':datum.name, 'Value':format(datum.value, '') + ''}"
              // }
            },
            enter: {
              shape: { value: "circle" },
              fill: { scale: "color", field: "setid" },
              stroke: { scale: "color", field: "setid" },
              strokeWidth: { value: 1.0 },
              strokeOpacity: { value: 1 },
              cursor: { value: "pointer" },
              x: { field: "x" },
              y: { field: "y" },
              size: { field: "size" }
            },
            update: {
              opacity: { value: 0.2 },
            }
          }
        },
        {
          name: "textmark",
          type: "text",
          from: { data: "textsource" },
          encode: {
            enter: {
              align: { value: "center" },
              baseline: { value: "middle" },
              fill: { value: "rgb(100 116 139)"},
              font: { value: "Satoshi" },
              fontSize: {value: 10 },
              fontWeight: { value: 400 },
              cursor: { value: "pointer" }
            },
            update: {
              x: { field: "x" },
              y: { field: "y" },
              text: { field: "setid" }
            }
          }
        },
      ]
    }
  }

  sparkline(values) {
    return {
      $schema: "https://vega.github.io/schema/vega-lite/v5.json",
      data: {
        values: [{ q: values }],
        name: "dataset"
      },

      transform: [
        {
          flatten: ["q"]
        },
        {
          window: [{
            op: "rank",
            as: "index"
          }]
        }
      ],

      height: 20,
      width: 100,
      padding: 0,

      mark: {
        type: "area",
        interpolate: "monotone",
        line: { color: "steelblue", strokeWidth: 1, interpolate: "monotone" },
        fill: {
          x1: 1,
          y1: 1,
          x2: 1,
          y2: 0,
          gradient: "linear",
          stops: [
            {
              offset: 0,
              color: "#B0C4DE20"
            },
            {
              offset: 1,
              color: "#B0C4DE"
            }
          ]
        }
      },
      encoding: {
        x: {
          field: "index",
          type: "quantitative",
          axis: {
            title: null,
            orient: "top",
            domain: false,
            ticks: false,
            labels: false,
            grid: false
          }
        },
        y: {
          field: "q",
          type: "quantitative",
          axis: {
            title: null,
            domain: false,
            labels: false,
            ticks: false,
            grid: false
          }
        }
      },

      config: {
        view: { stroke: "", fill: "", fillOpacity: 0 },
        header: {
          title: null
        }
      }
    }
  }

  bullet(values) {
    return {
      $schema: "https://vega.github.io/schema/vega-lite/v5.json",

      data: {
        values: [{
          lower: values[0],
          q1: values[1],
          median: values[2],
          q3: values[3],
          upper: values[4],
          average: values[5],
          b1: 200,
          b2: 400,
          b3: 600
        }]
      },

      height: 20,
      width: 100,
      padding: 0,

      layer: [
        {
          mark: { type: "bar", color: "#f0f0f0", clip: true },
          encoding: { x: { field: "b3", type: "quantitative", scale: { domain: [0, 600] }, title: null } }
        },
        {
          mark: { type: "bar", color: "#e8e8e8" },
          encoding: { x: { field: "b2", type: "quantitative" } }
        },
        {
          mark: { type: "bar", color: "#e0e0e0" },
          encoding: { x: { field: "b1", type: "quantitative" } }
        },
        {
          mark: { type: "bar", size: 8 },
          encoding: {
            x: { field: "lower", type: "quantitative" },
            x2: { field: "upper" },
            color: { value: "lightsteelblue" }
          }
        },
        {
          mark: { type: "bar", size: 12 },
          encoding: {
            x: { field: "q1", type: "quantitative" },
            x2: { field: "q3" },
            color: { value: "steelblue" }
          }
        },
        {
          mark: { type: "tick", color: "white", size: 14 },
          encoding: {
            x: {
              field: "median", type: "quantitative",
              axis: {
                domain: false,
                ticks: false,
                labels: false,
                grid: false
              }
            }
          }
        },
        {
          mark: { type: "tick", color: "black", size: 20, thickness: 2 },
          encoding: { x: { field: "average", type: "quantitative" } }
        }
      ],
      config: { view: { stroke: "", fill: "" } }
    }
  }

  spectrum(values) {
    return {
      $schema: "https://vega.github.io/schema/vega-lite/v5.json",

      height: 15,
      width: 100,
      padding: 0,

      data: {
        values: { f: values }
      },
      transform: [
        { flatten: ["f"] },
        { window: [{ op: "rank", as: "index" }] },
        { calculate: "datum.index - 1", as: "zero_based_index" }
      ],
      mark: "rect",
      encoding: {
        x: {
          field: "index",
          type: "quantitative",
          title: null,
          axis: null,
          scale: { domain: [0, 30] }
        },
        x2: { field: "zero_based_index" },
        color: {
          field: "f",
          type: "quantitative",
          title: null,
          legend: null
        }
      },
      config: {
        view: { stroke: "transparent", fill: "", fillOpacity: 0 }
      }
    }
  }
}

