import { Controller } from "@hotwired/stimulus"


// Connects to data-controller="maplibre"
export default class extends Controller {
  static values = {
    spec: String,
    element: String
  }

  disconnect() {
    console.log('disconnect!')
    this.element.map?.remove();
  }

  connect() {
    if (this.specValue > "") {

      const map = new maplibregl.Map({
        container: this.element,
        style: 'https://maps.iceflower.ai/styles/positron/style.json',
        center: [-100, 35],
        zoom: 4
      });

      this.element.map = map;

      map.on('load', () => {
        // Add a geojson point source.
        // Heatmap layers also work with a vector tile source.
        map.addSource('places', {
            'type': 'geojson',
            'data':
                '/maps/locations.json'
        });

        map.addLayer(
            {
                'id': 'places-point',
                'type': 'circle',
                'source': 'places',
                'minzoom': 3,
                'paint': {
                    // Size circle radius by earthquake magnitude and zoom level
                    'circle-radius': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        3, ['interpolate', ['linear'], ['get', 'mag'], 1, 1, 6, 4],
                        16, ['interpolate', ['linear'], ['get', 'mag'], 1, 5, 6, 50]
                    ],
                    // Color circle by earthquake magnitude
                    'circle-color': [
                        'match', ['get', 'merchant'],
                        'Chick-fil-A', 'red',
                        'Popeyes Louisiana Kitchen', 'green',
                        'KFC', 'blue',
                        'black'                        
                    ],
                    'circle-stroke-color': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        3, '#ffffff00',
                        10,'#ffffff'
                    ],
                    'circle-stroke-width': 1,
                    // Transition from heatmap to circle layer by zoom level
                    'circle-opacity': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        3, 0,
                        8, 1
                    ]
                }
            },
            ''
        );

        // Create a popup, but don't add it to the map yet.
        const popup = new maplibregl.Popup({
            closeButton: false,
            closeOnClick: false
        });

        map.on('mouseenter', 'places-point', (e) => {
            // Change the cursor style as a UI indicator.
            map.getCanvas().style.cursor = 'pointer';

            const coordinates = e.features[0].geometry.coordinates.slice();
            const description = e.features[0].properties.description;


            // Populate the popup and set its coordinates
            // based on the feature found.
            popup.setLngLat(coordinates).setHTML(description).addTo(map);
        });

        map.on('mouseleave', 'places-point', () => {
            map.getCanvas().style.cursor = '';
            popup.remove();
        });

        map.on('click', 'places-point', (e) => {
            // Change the cursor style as a UI indicator.

            const coordinates = e.features[0].geometry.coordinates;
            const merchant = e.features[0].properties.merchant;
            const store = e.features[0].properties.store;

            let color = '#000000';
            let group = 0;
            switch (merchant) {
                case 'Chick-fil-A': group = 1; color = '#ff0000'; break;
                case 'Popeyes Louisiana Kitchen': group = 2; color = '#00dd00'; break;
                case 'KFC': group = 3; color = '#0000ff'; break;
            }

            if (map.getLayer('selection-layer-' + group)) {
                map.removeLayer('selection-layer-' + group);
                map.removeSource('selection-' + group);
            }

            map.addSource('selection-' + group, {
                type: 'geojson',
                data: {
                    "type": "FeatureCollection",
                    "features": [{
                        "type": "Feature",
                        "properties": {},
                        "geometry": {
                            "type": "Point",
                            "coordinates": coordinates
                        }
                    }]
                }
             });

            map.addLayer(
                {
                    'id': 'selection-layer-' + group,
                    'type': 'circle',
                    'source': 'selection-' + group,
                    'minzoom': 3,
                    'paint': {
                        // Size circle radius by earthquake magnitude and zoom level
                        'circle-radius': [
                            'interpolate',
                            ['linear'],
                            ['zoom'],
                            3, ['interpolate', ['linear'], ['get', 'mag'], 1, 2, 6, 8],
                            16, ['interpolate', ['linear'], ['get', 'mag'], 1, 10, 6, 100]
                        ],
                        // Color circle by earthquake magnitude
                        'circle-stroke-color': color,
                        'circle-color': '#ffffff00',
                        'circle-stroke-width': 1,
                        // Transition from heatmap to circle layer by zoom level
                        'circle-opacity': [
                            'interpolate',
                            ['linear'],
                            ['zoom'],
                            3, 0,
                            8, 1
                        ]
                    }
                },
                ''
            );

            if (map.getLayer('area-layer-' + group)) {
                map.removeLayer('area-layer-' + group);
                map.removeSource('area-' + group);
            }

            map.addSource('area-' + group, {
                'type': 'geojson',
                'data':
                    '/maps/area.json?merchant=' + merchant + '&store=' + store
            });

            const area_layer = {
                'id': 'area-layer-' + group,
                'type': 'heatmap',
                'source': 'area-' + group,
                'minzoom': 3,
                'paint': {
                    'heatmap-opacity': 1.0,
                    // Increase the heatmap weight based on frequency and property magnitude
                    'heatmap-weight': [
                        'interpolate',
                        ['linear'],
                        ['get', 'mag'],
                        0, 0,
                        1, 0.8
                    ],
                    // Increase the heatmap color weight weight by zoom level
                    // heatmap-intensity is a multiplier on top of heatmap-weight
                    'heatmap-intensity': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        0, 1,
                        9, 1
                    ],
                    // Color ramp for heatmap.  Domain is 0 (low) to 1 (high).
                    // Begin color ramp at 0-stop with a 0-transparency color
                    // to create a blur-like effect.
                    'heatmap-color': [
                        'interpolate', 
                        ['linear'], 
                        ['heatmap-density'], 
                        0.50, color + '00', 
                        0.51, color + '18', 
                        0.85, color + '18', 
                        0.86, color + '30', 
                    ],
                    // Adjust the heatmap radius by zoom level
                    'heatmap-radius': [
                        'interpolate',
                        ["exponential", 2],
                        ['zoom'],
                        3, 1,
                        14, 1000
                    ]
                }
            }    

            map.addLayer(area_layer);

            const event = this.dispatch("add-merchant", { prefix: null, detail: { merchant, store, group, color } });

            const o = document.getElementById('overlap');
            const url = URL.parse(o.src);
            url.searchParams.set('g' + group, merchant + ':' + store); 
            o.src = url.toString();

            const r = document.getElementById('revenue-' + group);
            const urlr = URL.parse(r.src);
            urlr.searchParams.set('g', merchant + ':' + store);
            r.src = urlr.toString();
        });
    });

    // Add the navigation control
    map.addControl(new maplibregl.NavigationControl());
    }
  }
}

